.card-with-image img {
  height: 60rem;
  width: 40rem;
  padding-top: 2%;
}

#home #logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

#imprint .main-container {
  padding-bottom: 15rem;
}

#become-member-card img {
  width: 90%;
  height: 100%;
}

#home .card-with-image {
  width: 100% !important;
}

#statute {
  padding-top: 2%;
  padding-bottom: 10%;
}

#home .container {
  margin-top: -8%;
}

#sommernacht {
  width: 100%;
}

#ehrenamtspreis {
  width: 100% !important;
}

#ehrenamtspreis img {
  width: 50rem;
  height: 30rem;
}

#statute-site-card img {
  width: 40rem;
  height: 15rem;
}

#chairman-site-card img {
  width: 50%;
  height: 20%;
}

#zeitung-burgverein-erwacht-zum-leben img {
  width: 100%;
  height: 20%;
}

#dataprotection {
  padding-top: 2%;
}

#logo img {
  //	648 × 804 px
  width: 648px;
  height: 804px;
}

#statute .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#small-header {
  background-image: $hohenfels-img;
  background-size: cover;
  height: 20rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: $font-carolingia;
  text-shadow: 5px 5px #000000;
}

#small-header h1 {
  padding-top: 5%;
}

#gruendung-01 {
  width: 100% !important;
}

#gruendung-01 img {
  width: 50%;
  height: 100%;
}

#home #logo .card-img-center {
  margin-bottom: -10%;
  width: 50%;
}

#home #logo .card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home #logo .card-img-center {
  padding-top: 2%;
  padding-bottom: 10%;
}

#logo-site-card {
  padding-top: 3%;
}

#logo-site-card img {
  width: 50%;
  height: 50%;
}

#dp-site .card-header {
  text-decoration: underline;
  font-weight: bold;
}

#lage-01 img {
  width: 50%;
  height: 40%;
}

#karte-schloss-hohenfels img {
  width: 50%;
  height: 20%;
}

#modelle-01 img,
#modelle-02 img,
#modelle-03 img {
  width: 70%;
  height: 20%;
}

#modelle-04 img {
  width: 40%;
  height: 10%;
}

#current-picture img {
  width: 50%;
  height: 20%;
}

#images {
  width: 100% !important;
}

#images img {
  width: 50%;
  height: 20%;
}

#project-cellar #cellar-01 img {
  width: 40%;
  height: 20%;
}

#grabung-2002 img {
  width: 50%;
  height: 20%;
}

#keller-skizze img {
  width: 40%;
  height: 20%;
}

#project-donationboard,
#project-modell {
  height: 60rem;
}

#flyer_summernight img {
  width: 80%;
  height: 20%;
}

#project-lecturetrail {
  height: 50rem;
}


#guestbook .guestbook-form {
  width: 100% !important;
  background-color: $whitish-color-01;
  padding: 2%;
  color: $greyish-color-01 !important;
  border-radius: 1.2% !important;
}

.guestbook-form h1 {
  text-align: center;
}

.guestbook-form .btn {
  align-items: center;
}


.btn-form-field {
  display: flex;
  align-items: center;
  flex-direction: column;

}

#laufzeile {
  background-size: cover;
  height: 40rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: $font-carolingia;
  background-image: url("../../../public/assets/images/santa_claus_PNG9983.png");
  background-size: 35%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 2%;
}


#laufzeile a {
  margin-top: 10%;
  color: $greyish-color-01 !important;
  background-color: none !important;
  text-align: center !important;
  font-size: 190% !important;
  font-family: $font-grenze !important;
  margin-left: -4%;
  text-decoration: none !important;

}

#competition {
  min-height: 78vh;
}

#competition-data-protection-card .card-body {
  font-size: 80% !important;
  display: block;
  hyphens: auto; 
	text-align: justify;
}

#competition-data-protection-card .card-body b {
  font-size: 80%;
}

#competition .btn {
  width: 80%; /* Passe die Breite an */
  height: 10rem; /* Passe die Höhe an */
  background-color: #885a06; /* Orange Farbe */
  color: #ffff;
  border-radius: 10px; /* Abgerundete Ecken */
  border: none; /* Kein Rand */
  box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.783); /* Innerer Schatten */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 150%;
  cursor: pointer;
}

.question-form .btn-form-field .btn {
  width: 80%; /* Passe die Breite an */
  margin-top: -20%;
  height: 2rem !important; /* Passe die Höhe an */
}

#laufzeile a:hover {
  text-decoration: underline !important;  
}

#competition .backToStart .card-body {
  text-align: center !important;
}

.competition-data-protection {
  font-size: 70%;
  hyphens: auto; 
	text-align: justify;
}

.competition-data-protection b {
  font-size: 100% !important;
}

#competition-img img{
  height: 100% !important;
}

.competition-card-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.competition-card-btn .card-body {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.competition-card-btn .card-body .btn {
  padding: 0%;
  margin: 1%;
  width: 100%;
  color: $whitish-color-01;
  border: none !important;
  border-radius: 0%;
  height: 10rem;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
}

.competition-welcome-card .card-header {
font-weight: bolder;
}


.competition-welcome-card li {
  font-size: 100% !important;
}

#special-example-btn {
  color: $whitish-color-01 !important;
}

.price-text {
  text-align: left;
}

#gedichtband-img .card-body {
  display: flex;
  align-items: start;
}

#gedichtband-img .card-with-image {
  align-items: start;
}

#gedichtband-img img {
  height: 10%;
}

.price-text b:first-child {
  font-size: 130%;
}

#gedichtband-img .card-img-center {
  margin-left: auto;
  margin-right: auto;
}

.question-form {
  margin-bottom: 2%;
  background-color: $whitish-color-01;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  color: $greyish-color-01 !important;
  font-size: 80%;
}

.question-form h1 {
  font-size: 150%;
  font-weight: bolder;
}

.question-form .btn {
  border: none;
  font-size: 90% !important;
}

#price-img {
  display: flex;
  justify-content: center;
}

#selbstverlagslogo {
  width: 50%;
  height: 20% !important;
}

.questions-card {
  padding: 2%;
}

#laufzeileQuestions { 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  flex-direction: column;
  animation: marquee 3s linear infinite, wobble 1s ease-in-out infinite;
}

#logo_img_questions .card-body {
  text-align: justify !important;
}

@keyframes wobble {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(5px) rotate(2deg); }
  50% { transform: translateX(-5px) rotate(-2deg); }
  75% { transform: translateX(3px) rotate(1deg); }
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

#clickSymbol {
  background-color: $whitish-color-01;
  width: 30%;
  padding: 1%;
  border-radius: 2em;
  text-align: center;
  text-wrap: wrap;

}

#laufzeileQuestions a {
  display: flex;
  align-items: center;
  justify-content: center;
}

#homepageQuestionsLogo { 
  width: 80% !important;
}


#questionsPage #backToStart .card-body {
  text-align: center;
}

#logo_img_questions img {
  width: 100%;
  height: 20%;
}

#questionAccordion .accordionbutton:focus {
  background-color: #212529;
  border: none !important;
  box-shadow: none;
}

#questionAccordion {
  width: 100% !important;
  margin-bottom: 2%;
}

#questionAccordion .accordion-button {
  font-size: 90%;
}


#questionAccordion .accordion-body {
  text-align: justify;
}

#questionAccordion .accordion-body * {
  font-size: 100% !important;
}

#questionAccordion .accordion-button:not(.collapsed) {
  color: $whitish-color-01;
  background-color: $greyish-color-01;
  border-bottom: 0.9px solid $whitish-color-01;
}

.accordion-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

#questionAccordion button:focus {
  box-shadow: none;
}

#logo_img_questions img {
  width: 50%;
}

.accordion-image-wrapper img {
  width: 60%;
}

#gewinner-bild img{
  width: 80%;
  height: 20%;
}

@media (max-width: 2100px) {
  #laufzeile {
    height: 30rem;
    font-size: 100% !important;
  }

  #laufzeile a {
    margin-top: 8%;
    font-size: 150% !important;
  }
}

@media (max-width: 1441px) {
  #laufzeile {
    height: 40rem;
    font-size: 150% !important;
    background-size: 60%;
  }

  #laufzeile a {
    margin-top: 20%;
    font-size: 110% !important;
  }
}

@media (max-width: 1200px) {
  #laufzeile {
    height: 30rem;
    font-size: 120% !important;
    background-size: 60%;
  }

  #laufzeile a {
    margin-top: 15%;
    font-size: 100% !important;
  }
}

@media (max-width: 1025px) {
  #laufzeile {
    height: 30rem;
    font-size: 110% !important;
    background-size: 60%;
  }

  #laufzeile-questions  img {
    width: 30rem;
  }

  #laufzeile a {
    margin-top: 15%;
    font-size: 110% !important;
  }
  #laufzeile a b {
    font-size: 100% !important;
  }
}

@media (max-width: 992px) {
  #competition-img img {
    width: 90%;
  }

  #gedichtband-img img {
    height: 10%;
    width: 80%;
  }

  .competition-card-btn .card-body {
    flex-direction: column;
  }
  .competition-card-btn .card-body .btn {
    height: 5rem;
  }
}

@media (max-width: 769px) {
  #laufzeile {
    height: 40rem;
    font-size: 120% !important;
    background-size: 90%;
  }

  #laufzeile a {
    margin-top: 35%;
    font-size: 100% !important;
  }
}

@media (max-width: 700px) {
  #laufzeile {
    height: 30rem;
    font-size: 120% !important;
    background-size: 90%;
  }

  #laufzeile a {
    margin-top: 25%;
    font-size: 90% !important;
  }

  #laufzeile-questions  img {
    width: 15rem;
  }
}

@media (max-width: 426px) {
  #laufzeile {
    height: 30rem;
    font-size: 260% !important;
    background-size: 100% !important;
  }

  #laufzeile a {
    margin-top: 55%;
    font-size: 30% !important;
  }
  #competition .btn {
    width: 80%; /* Passe die Breite an */
    height: 5rem; /* Passe die Höhe an */
    font-size: 120%;
  }
}

@media (max-width: 376px) {
  #laufzeile {
    height: 20rem;
    font-size: 250% !important;
    background-size: 100% !important;
  }

  #laufzeile a {
    margin-top: 35%;
    font-size: 25% !important;
  }
}

@media (max-width: 322px) {
  #laufzeile {
    height: 20rem;
    font-size: 200% !important;
    background-size: 100% !important;
  }

  #laufzeile a {
    margin-top: 50%;
    font-size: 25% !important;
  }
}

.competition-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.competition-container .text-section {
  flex: 2;
  margin-right: 20px;
}

.competition-container .text-section h1 {
  font-size: 1.8em;
  margin-bottom: 20px;
}
.competition-container .text-section p {
  margin-bottom: 15px;
}
.competition-container .image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.competition-container .image-section img {
  max-width: 100%;
  height: 0%;
}
.competition-container q {
  border: 0.01rem solid $greyish-color-01;
  text-align: center;
  flex: 1.5;
  margin-right: 2%;
  font-style: italic;
  font-size: 80%;
}

.competition-container:nth-child(2) .text-section {
  flex: 1;
}

.competition-container:nth-child(3) q {
  margin: 0;
}

.text-section h4 {
  font-size: 120%;
  font-weight: bolder;
}

.competition-container .svg-section {
  flex: 1;
  font-size: 200%;
}

.competition-container .svg-section svg {
  width: 85% !important;
  height: 85% !important;
}

.competition-container:last-child .image-section {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.competition-container a {
  color: $greyish-color-01 !important;
  text-decoration: none !important;
}

.competition-container a:hover {
  text-decoration: underline !important;
}