@font-face {
  font-family: "gotische-initialen.regular";
  src: url("../fonts/gotische-initialen.regular.ttf") format("truetype");
}

@font-face {
  font-family: "carolingia";
  src: url("../fonts/carolingia.ttf") format("truetype");
}

@font-face {
  font-family: "Grenze";
  src: url("../fonts/Grenze-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Salium";
  src: url("../fonts/Salium-Pro.ttf") format("truetype");
}

$whitish-color-01: #ebe2e2;
$whitish-color-02: #d7c4c4;
$greyish-color-01: #212529;
$greyish-color-02: #454347;
$blueish-color: #3d1f9b;
$greenish-color: #8bc34a;
$orangish-color: #8f3109;
$yellowish-color: #ffcc00;
$transparent-black: rgba(0, 0, 0, 0.363);
$font-grenze: "Grenze", serif;
$font-gotic: "gotische-initialen.regular", sans-serif;
$font-carolingia: "carolingia";
$font-salium: "Salium";
$base-font-size: 1rem;
$text-shadow: -0.0625rem -0.0625rem 0 black, 0.0625rem -0.0625rem 0 black,
  -0.0625rem 0.0625rem 0 black, 0.0625rem 0.0625rem 0 black;

$hohenfels-img: url("../../../public/assets/images/general_images/hes2741.jpg");
//$hohenfels-img: url("../../../public/assets/images/general_images/banner-skizze.png");