$font-size-base: 1.125rem;
$font-size-large: 1.563rem;
$border-width: 1.5em;
$rbgc: #d64b4b;
$ribbon-shadow: rgba(0, 0, 0, 0.3) 0 0.063rem 0.063rem;

.ribbon {
  width: 100%;
  position: relative;
  text-align: center;
  font-size: $font-size-base;
  background: $rbgc;
  box-shadow: $ribbon-shadow;
  font-family: $font-carolingia;

  p {
    font-size: $font-size-large;
    color: $whitish-color-01;
    text-shadow: #212529 0 0.063rem 0;
    margin: 0;
    padding: 0.2em 1em;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -1em;
    border: $border-width solid $rbgc;
    z-index: -1;
  }

  &::before {
    left: -2em;
    border-right-width: $border-width;
    border-left-color: transparent !important;
    //box-shadow: $ribbon-shadow;
  }

  &::after {
    right: -2em;
    border-left-width: $border-width;
    border-right-color: transparent !important;
    box-shadow: $ribbon-shadow;
  }

  .ribbon-content::before,
  .ribbon-content::after {
    position: absolute;
    display: block;
    bottom: -1em;
    content: "";
  }

  .ribbon-content::before {
    left: 0;
    border-width: $border-width 0 0 $border-width;
  }

  .ribbon-content::after {
    right: 0;
    border-width: $border-width $border-width 0 0;
  }

  &-stitches-top {
    margin-top: 0.1em;
    border-top: 0.063rem dashed rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0.125rem rgba(255, 255, 255, 0.5);
  }

  &-stitches-bottom {
    margin-bottom: 0.1em;
    border-top: 0.063rem dashed rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.125rem 0.125rem rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 576px) {
    /* Adjust styles for smaller screens */
    font-size: calc($font-size-base * 0.8);

    p {
      font-size: calc($font-size-large * 0.8);
    }
  }
}
