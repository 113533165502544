@media (max-width: 2100px) {
  .main-container {
    padding-top: 12%;
  }
}

@media (max-width: 1988px) {
  .main-container {
    padding-top: 16%;
  }
  #small-header {
    padding-top: 15%;
    height: 30rem;
  }
}

@media (max-width: 1600px) {
  .video-player video {
    min-width: 30em;
  }
}

@media (max-width: 1441px) {
  #upcoming-events {
    width: 80% !important;
  }
  
  .video-player video {
    min-width: 70%;
  }

  #small-header {
    padding-top: 10%;
    height: 30rem;
  }

  .navbar-brand,
  .nav-link {
    padding-left: 4%;
  }

  .main-container {
    padding-top: 20%;
  }

  #statute {
    padding-bottom: 20%;
  }

  .dropdown-item {
    padding-left: 5%;
  }

  #project-lecturetrail,
  #project-donationboard,
  #project-modell {
    height: 65rem;
  }

  #small-header h1 {
    padding-top: 15%;
  }
}

@media (max-width: 1025px) {
  .video-player video {
    min-width: 10em;
  }

  .main-container {
    padding-top: 20%;
  }

  #logo img {
    width: 70%;
    height: 70%;
  }

  #statute-site-card img {
    width: 80%;
    height: 80%;
  }

  #statute {
    padding-bottom: 25%;
  }

  #project-donationboard,
  #project-modell {
    height: 62rem;
  }
  #ehrenamtspreis img {
    width: 80%;
    height: 60%;
  }
  .dropdown-item {
    padding-left: 8%;
  }
  .dropdown-menu {
    border: none;
  }
}

@media (max-width: 992px) {
  #ehrenamtspreis img {
    width: 80%;
  }

  #statute {
    padding-bottom: 45%;
  }

  #sommernacht img {
    width: 60%;
    height: 20%;
  }
  #small-header h1 {
    padding-top: 15%;
  }
}

@media (max-width: 769px) {
  .video-player video {
    min-width: 10em;
  }

  .main-container {
    padding-top: 10%;
    margin-top: 5%;
  }

  #ehrenamtspreis img {
    width: 80%;
    height: 60%;
  }

  #dp-site .card-header,
  #dp-site .card,
  #dp-site .card-body {
    word-break: break-word;
  }

  header {
    text-decoration: underline;
    font-weight: bold;
  }

  #wappen-adelsgeschlecht img {
    width: 50%;
    height: 50%;
  }

  #project-lecturetrail,
  #project-donationboard,
  #project-modell {
    height: 64rem;
  }
}

@media (max-width: 576px) {
  #statute {
    padding-top: 20%;
    padding-bottom: 20%;
    height: 65rem;
  }

  #mail-link a {
    font-size: 70% !important;
  }

  #insta-link a {
    font-size: 70% !important;
  }

  #association-logo .card img {
    width: 50%;
  }

  #imprint {
    height: 65rem;
  }

  #ruin-position {
    height: 65rem;
  }

  #project-lecturetrail,
  #project-donationboard,
  #project-modell {
    height: 65rem;
  }

  .navbar {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .dropdown-item {
    padding-left: 12%;
  }
  .main-container {
    margin-top: 15%;
  }
}

@media (max-width: 426px) {
  .video-player video {
    min-width: 5em;
    height: 8em;
  }

  #small-header {
    padding-top: 15%;
    height: 20rem;
  }

  #association-logo {
    padding-bottom: 28%;
  }

  .main-container {
    margin-top: 20%;
  }

  #sommernacht img {
    width: 80%;
    height: 70%;
  }
}

@media (max-width: 376px) {
  .video-player video {
    min-width: 4em;
    height: 7em;
  }

  #small-header {
    padding-top: 25%;
    height: 20rem;
  }

  .main-container {
    margin-top: 30%;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 321px) {
  .main-container {
    margin-top: 40%;
  }
  #visitors {
    text-align: center;
  }
}
