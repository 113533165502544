* {
  font-size: 1.4rem;
  text-align: left;
}

.card-title {
  font-size: 120%;
}

.card-header {
  font-size: 150%;
}

.navbar,
.navbar * {
  font-size: 100%;
}

.navbar-brand {
  font-size: 120%;
}

footer div {
  font-size: 120%;
}

h1 {
  font-size: 2rem;
}

h2,
h3,
h4 {
  font-size: 2rem;
}
b {
  font-size: 100%;
}

.card-body b {
  font-size: 100%;
  text-align: center !important;
}

.ribbon-content {
  font-size: 90%;
}

.ribbon-content p b {
  font-size: 110%;
}

#small-header h1 {
  font-size: 250%;
  text-align: center;
}

#small-header h3 {
  font-size: 150%;
  text-align: center;
}

h3 b {
  font-size: 1rem;
}

.card-body h3 b {
  font-size: 70% !important;
}

#copyright {
  font-size: 85%;
}

.dropdown-item {
  font-size: 100%;
}

#guestbook {
  font-size: 100%;
}
#guestbook h1 {
  font-size: 120%;
}
#guestbook .form-label {
  font-size: 80%;
  padding-left: 2%;
}
@media (max-width: 1999px) {
  .navbar {
    font-size: 90%;
  }
}

@media (max-width: 1800px) {
  .navbar {
    font-size: 80%;
  }
  .accordion-header {
    font-size: 120%  !important;
  }
}

@media (max-width: 1630px) {
  .navbar {
    font-size: 75%;
  }
}

@media (max-width: 1540px) {
  .navbar {
    font-size: 70%;
  }
}


@media (max-width: 1450px) {
  .navbar {
    font-size: 60%;
  }
}

@media (max-width: 1025px) {
  * {
    font-size: 1.3rem;
  }

  .navbar-brand {
    font-size: larger;
  }

  .card-title {
    font-size: 120%;
  }

  .card-header {
    font-size: 150%;
  }

  .nav-link {
    font-size: 95%;
  }

  footer div {
    font-size: 120%;
  }

  h1 {
    font-size: 200%;
  }

  h2,
  h3,
  h4,
  b {
    font-size: 150%;
  }
  footer,
  footer * {
    font-size: 95%;
  }
  .ribbon-content {
    font-size: 90%;
  }
  .ribbon-content p b {
    font-size: 95%;
  }
}

@media (max-width: 576px) {
  #small-header h1 {
    font-size: 150%;
    padding-top: 20%;
  }
  .navbar-brand {
    font-size: 90%;
  }
  #small-header h3 {
    font-size: 120%;
  }
  #copyright {
    text-align: center;
  }
  #upcoming-events .card-body,
  #upcoming-events .card-body li {
    text-align: left !important;
  }
  .card,
  .card-body,
  .card * {
    font-size: 90%;
  }

  .ribbon-content {
    font-size: 80%;
  }
  .ribbon-content p b {
    font-size: 81%;
  }
  .card-text,
  .card-text * {
    font-size: 90% !important;
  }
  .card-header {
    font-size: 100% !important;
  }
  footer {
    font-size: 80%;
  }
  footer a i{
    font-size: 20%;
  }
  #visitors {
    font-size: 90%;  
    text-align: center;
  }
  #guestbook {
    font-size: 80%;
  }
  #guestbook h1 {
    font-size: 95%;
  }
  #guestbook .form-label {
    font-size: 75%;
  }

  .accordion-header {
    font-size: 110%  !important;
  }
  .accordion-body {
    font-size: 70% !important;
  }
}

@media (max-width: 376px) {
  #small-header h1 {
    font-size: 150%;
    padding-top: 20%;
    text-align: center;
  }
  #small-header h3 {
    font-size: 120%;
    text-align: center;
  }
  footer {
    font-size: 80%;
  }
  footer a i{
    font-size: 20%;
  }
  #visitors {
    font-size: 90%;  
    text-align: center;
  }
}
