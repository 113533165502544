// ===================================
// Imports: External Stylesheets
// ===================================
@import "./settings.scss";
@import "./ribbon.scss";
@import "./special-pages.scss";
@import "./media.scss";
@import "./font-settings.scss";

// ===================================
// Base Styles
// ===================================
body {
  // Set background and text color, and apply font family
  background-color: $greyish-color-02;
  color: $whitish-color-01;
  font-family: $font-grenze, sans-serif;
}

#imprint {
  min-height: 96vh;
}

// ===================================
// Link Styles
// ===================================
// Default link styles
a:link, a:visited {
  color: $greyish-color-01;
}

// Active state for links
a:active {
  color: #031618;
}

// Hover effect for links
a:hover {
  color: #0b042a;
}

// ===================================
// Navbar Styles
// ===================================
// Default styles for navbar links
.navbar a,
.navbar-dark .navbar-nav .nav-link {
  color: $whitish-color-01;
}

// Spacing for the navbar brand
.navbar-brand {
  margin-left: 1%;
}

// Padding for navigation
nav {
  padding-left: 5%;
  font-family: $font-carolingia; // Apply specific font to navigation
}

// Additional spacing for individual nav links
.nav-link {
  padding-left: 4%;
}

// ===================================
// Container Styles
// ===================================
// Main content container styling
.container {
  padding-top: 10%;   // Top padding for breathing room
  padding-bottom: 5%; // Bottom padding for breathing room
  display: flex;      // Align content in column layout
  flex-direction: column;
  align-items: center;
  width: 100%;
}

// Padding for the main container element
.main-container {
  padding-top: 10%;
}

// ===================================
// Card Component Styles
// ===================================
// General card styles
.card {
  background-color: $whitish-color-01; // Background color for cards
  border: none;                        // Remove borders
  width: 100% !important;              // Ensure full width
  margin-bottom: 4%;                   // Bottom margin between cards
  color: $greyish-color-01;            // Card text color
}

// Pointer cursor for images inside cards
.card img {
  cursor: pointer;
}

// Specific styles for cards containing videos or images
.quiz-card,
.card-with-video,
.card-with-image {
  width: 100%;               // Ensure full width
  margin: 0;                 // Remove margins
  display: flex !important;  // Display as flexbox
  margin-bottom: 2%;         // Space between cards
  align-items: center;       // Align content vertically
}

// Ensure embedded videos take up full width
.card-with-video iframe {
  width: 100%;
  left: 0;
}

// Card text styles
.card-title,
.card-text {
  color: $greyish-color-01;  // Color for card titles and text
}

// Button style inside card body
.card-body a button {
  background-color: $greyish-color-01;
}

// Center align text in the card header
.card-header {
  text-align: center;
}

// Ribbon content text alignment
.ribbon-content p {
  text-align: center;
}

// ===================================
// Footer Styles
// ===================================
// General footer styles
footer {
  display: flex;             // Flexbox layout
  flex-direction: column;    // Align items vertically
  align-items: center;       // Center align content
  height: 20%;               // Footer height
  bottom: 0;                 // Stick footer to bottom
  width: 100%;               // Full width
  padding-top: 2%;           // Padding at the top
  background-color: $greyish-color-01; // Background color
  position: static;          // Position footer at the bottom of the document flow
  text-align: center !important; // Center text
  font-family: $font-carolingia;  // Font family for footer
}

// Footer link styles
footer a {
  color: $whitish-color-01 !important; // Link color in footer
  margin-top: 0;
  width: 100%;
  text-align: center !important;       // Ensure centered links
}

// Footer email image styling
#footer-mail img {
  padding-right: 0.5%; // Add space to the right of the image
  width: 1.8%;         // Scale image size
}

// ===================================
// Page-Specific Styles
// ===================================
// Guestbook section minimum height
#guestbook {
  min-height: 85vh;  // Ensure guestbook takes up most of the viewport height
}

// Ribbon background color for the home page
#home .ribbon {
  background-color: $greenish-color;
}

// Ribbon border styling for home page
#home .ribbon::before,
#home .ribbon::after {
  border: 1.5em solid $greenish-color;
}

// ===================================
// Cookie Consent Styles
// ===================================
.CookieConsent #rcc-confirm-button {
  background-color: $greyish-color-02 !important;
  color: $whitish-color-01 !important;
}

// ===================================
// Modal Styles
// ===================================
// Ensure modal content takes full width
.modal-content {
  width: 100%;
}

// Double the size of images inside the modal body
.modal-body img {
  width: 200% !important;
}

// ===================================
// Image Styles
// ===================================
// Ensure header image scales properly
.header-image {
  width: 100%;   // Full width
  height: auto;  // Maintain aspect ratio
}

// ===================================
// Audio Player Styles
// ===================================
// 
.audio-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center !important;
  align-items: center;
}

.audio-controls {
  display: flex;
  flex-direction: row;
  padding: 4%;
  margin-left: 0%;
}

.audio-player .audio-controls .btn {
  margin: 2% !important;
  width: 50%;
  height: 50%;
}

.audio-player #nowPlaying {
  text-align: center;
}

.audio-player .playPauseBtn {
  width: 80%;
  height: 80%;
}

.audio-player .playPauseBtn svg {
  font-size: 400%;
}

.audio-player .progress {
  margin-top: 4%;
  width: 100%;
}

.volume-control {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.volume-input {
  width: 100%;
  padding-top: 2%;
  flex-wrap: nowrap !important;
}
$track-bg-color: #72829c;
$track-border-color: #010101;
$track-border-radius: 0.08125rem; // 1.3px
$track-height: 0.525rem; // 8.4px
$thumb-width-height: 1.375rem; // 22px
$thumb-bg-color: #ffffff;
$thumb-border-color: #000000;
$thumb-border-radius: 50%;
$focus-track-bg-color: #7e8da4;
$fill-lower-bg-color: #677893;
$ms-fill-upper-radius: 0.1625rem; // 2.6px
$track-border-width: 0.0125rem; // 0.2px
$track-margin: 0.425rem 0; // 6.8px
$ms-thumb-margin-top: 0rem; // 0px


.cursor-pointer-large {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.cursor-pointer-large:hover {
  transform: scale(1.1);
  cursor: grab;
}

input[type="range"] {
  width: 100% !important;
  margin: $track-margin;
  background-color: transparent;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: $track-bg-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-border-radius;
    height: $track-height;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    margin-top: -0.4375rem; // -7px
    width: $thumb-width-height;
    height: $thumb-width-height;
    background: $thumb-bg-color;
    border: $track-border-width solid $thumb-border-color;
    border-radius: $thumb-border-radius;
    cursor: pointer;
    -webkit-appearance: none;
  }

  &:focus::-webkit-slider-runnable-track {
    background: $focus-track-bg-color;
  }

  &::-moz-range-track {
    background: $track-bg-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-border-radius;
    height: $track-height;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: $thumb-width-height;
    height: $thumb-width-height;
    background: $thumb-bg-color;
    border: $track-border-width solid $thumb-border-color;
    border-radius: $thumb-border-radius;
    cursor: pointer;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: $track-margin;
    color: transparent;
    height: $track-height;
    cursor: pointer;
  }

  &::-ms-fill-lower {
    background: $fill-lower-bg-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $ms-fill-upper-radius;
  }

  &::-ms-fill-upper {
    background: $track-bg-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $ms-fill-upper-radius;
  }

  &::-ms-thumb {
    width: $thumb-width-height;
    height: $thumb-width-height;
    background: $thumb-bg-color;
    border: $track-border-width solid $thumb-border-color;
    border-radius: $thumb-border-radius;
    cursor: pointer;
    margin-top: $ms-thumb-margin-top;
  }

  &:focus::-ms-fill-lower {
    background: $track-bg-color;
  }

  &:focus::-ms-fill-upper {
    background: $focus-track-bg-color;
  }
}

@supports (-ms-ime-align: auto) {
  input[type="range"] {
    margin: 0;
  }
}

// ===================================
// Quiz Styles
// ===================================
// 
.quiz-card .card-body {
  display: flex;
  flex-direction: column;
  padding: 2%;
  margin: 1%;
}

.quiz-card .card-body .btn {
  margin: 2%;
}

.quiz-card .result {
  text-align: center;
}


// ===================================
// Video Player
// ===================================
// 
.videoCard {
  align-items: center;
}

.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-player video {
  min-width: 10rem;
  width: 80% !important;
}

